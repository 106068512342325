@import "../../scss/colors.scss";
.setings_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  // background: var("background");
  background: var(--background);
  padding: 0;

  .setings_nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 8px;
    box-sizing: border-box;

    .button_ {
      width: 45px;
      height: 30px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      position: absolute;
      padding: 0;

      .svg {
        width: 100%;
        fill: white;
      }
    }

    .title_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0;

      .title {
        margin: 0;
        font-size: 33px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        color: white;
        text-align: center;
      }
    }
  }

  .setings_wrap {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background-color: var(--list);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding-bottom: 5%;

    .button_ {
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;

      min-width: 179px;
      background: var(--btn-color);
      padding: 20px 10px;
      border: none;
      border-radius: 15px;
      color: var( --nav-text-active);
    }

    .desr {
      font-family: 'Rubik', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: black;
    }
    .setings_params {
      max-width: 383px;
      position: absolute;
      top: 120px;
      width: 80%;
      background: var(--list_item);
      box-shadow: 7px 7px 5px 0px #0000001a;
      border-radius: 15px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .text {
        font-family: 'Rubik', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #adadad;
        margin: 0;
      }
      .link {
        text-decoration: none;
        width: 100%;
      }

      .sound_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
      }

      .theme_btn {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        justify-content: space-between;
        margin-bottom: 25px;
        margin-top: 31px;

        .svg_arrow {
          width: 30px;
          transform: rotate(180deg);
        }
      }
    }
    .custom {
      display: flex;
      align-items: center;
      width: 100%;
      height: 30px;
      justify-content: space-between;
      margin-bottom: 0px;
      margin-top: 0px;

      .svg_arrow {
        width: 30px;
        transform: rotate(180deg);
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background: var( --sound-btn);
}

input:focus + .slider {
  box-shadow: 0 0 1px var( --sound-btn);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
