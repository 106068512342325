@import "../../scss/colors.scss";
.categories_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background);
  padding-bottom: 50px;

  .list {
    background: var(--list);
    width: 100%;
    padding: 20px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    border-radius: 50px;
    box-shadow: 0px 4px 4px 0px #00000040 inset;

    .list_item {
      height: 152px;
      background: var(--list_item);
      width: 85%;
      border-radius: 15px;
      margin-bottom: 20px;
      padding: 5px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link{
        padding: 10px;

        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .title {
        font-size: 18px;
        margin-top: 0;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 0;
        margin-bottom: 6px;
        color: var(--title);
      }
      .descr {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--descr);
        margin: 0;
        width: 70%;
        text-align: center;
      }
      .image {
        width: 116px;
        height: 78px;
        padding-left: 5px;
      }
    }
  }


  .head_container{
    .custom_btn{
      display: none;
    }
   
  }
 

}
