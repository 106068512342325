@import "../scss/colors.scss";

.modal_wrap {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
  max-width: 480px;

  .modal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px;
    border-radius: 15px;
    border: solid 1px var(--list_item);
    width: 90%;
    background-color: var(--quote-text-container);
    .link {
      margin-bottom: 24px;
      padding: 11px 60px;
      box-sizing: border-box;
      border-radius: 15px;
      color: var(--btn-text);
      font-family: Outfit;
      font-size: 35px;
      font-weight: 500;
      line-height: 44px;
      letter-spacing: 0em;
      text-align: center;
      background: var(--btn-color);
      text-decoration: none;
      width: 80%;

    }
  }
}
