// $colors: (
//   'background':  linear-gradient(180deg, #FF68B3 0%, #FF007F 100%),
//   'list': #C1FEFF,
//   'list_item':#FFFFFF,
//   'title': #393939,
//   'descr':#888888,
//   'btn-color':linear-gradient(180deg, #03FFFF 0%, #01ABB6 100%),
//   'btn-text':#424242,
//   'quote-text-container': #C1FEFF9E,
//   'quote-title':#6C706B,
//   'quote-text': #222222,
//   'acent': linear-gradient(180deg, #03FFFF 0%, #01ABB6 100%),

// );

:root{
  --background:  linear-gradient(180deg, #FF68B3 0%, #FF007F 100%);
  --list: #C1FEFF;
  --list_item:#FFFFFF;
  --title: #393939;
  --descr:#888888;
  --btn-color:linear-gradient(180deg, #03FFFF 0%, #01ABB6 100%);
  --btn-text:#424242;
  --quote-text-container: #C1FEFF9E;
  --quote-text-container-favorites: #C1FEFF9E;
  --quote-title:#6C706B;
  --quote-text: #222222;
  --acent: linear-gradient(180deg, #03FFFF 0%, #01ABB6 100%);
  --nav-acent:#04FEFE;
  --nav-text:#424242;
  --nav-text-active:#424242;
  --quote-text-favorites:  #222222;
  --sound-btn:#E5386D;
  --arrow-fill:#000000;
  --cog-fill: #04FEFE;


}






