@font-face {
    font-family: 'Poppins';
    src: url('../font/Poppins/Poppins-Regular.ttf') format('ttf'),
         url('../font/Poppins/Poppins-Thin.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }

 
  @font-face {
    font-family: 'Rubik';
    src: url('../font/Rubik/Rubik-VariableFont_wght.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Outfit';
    src: url('../font/Outfit/Outfit-VariableFont_wght.ttf') format('truetype'),
         url('../font/Outfit/static/Outfit-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

  body{
    font-family: 'Poppins', sans-serif;
        margin: 0;
       
      
}


.container{
    max-width: 480px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
}
 button{
    font-family: 'Outfit', sans-serif ;
}

ul{
    padding: 0;
}
img{
    width: 100%;
    height: auto;
    display: block;
}

html.dark-blue{
    
    --background: linear-gradient(180deg, #041955 0%, #3450A1 100%) ;
    --btn-color:linear-gradient(180deg, #BD03FF 0%, #A701B6 100%);
    --nav-acent:linear-gradient(180deg, #BD03FF 0%, #A701B6 100%);
    --nav-text-active:#fff;
    --nav-text: #fff;
    --quote-text-container: #2727279E;
    --quote-text:#FFFFFF;
    --acent: linear-gradient(180deg, #BD03FF 0%, #A701B6 100%);
    --list: #2727279E;
    --arrow-fill:#fff;
    --btn-text:#fff;
  --quote-text-container-favorites: #C1FEFF9E;
  --quote-text-favorites: #222222;
  --sound-btn: linear-gradient(180deg, #BD03FF 0%, #A701B6 100%);
  --cog-fill: #BD03FF;

}

html.purple{



    --background:   linear-gradient(180deg, #7B05A5 0%, #EB00FF 100%);
    --btn-color: linear-gradient(180deg, #041955 0%, #3450A1 100%);

    --nav-acent: linear-gradient(180deg, #041955 0%, #3450A1 100%);
    --nav-text-active:#fff;
    --nav-text: #fff;
    --quote-text-container: #2727279E;
    --quote-text:#FFFFFF;
    --acent: linear-gradient(180deg, #041955 0%, #3450A1 100%);
    --list: #2727279E;
    --arrow-fill:#fff;
    --btn-text:#fff;
//   --quote-text-container-favorites: #C1FEFF9E;
//   --quote-text-favorites: #fff;
  --sound-btn: linear-gradient(180deg, #041955 0%, #3450A1 100%);

  --cog-fill: #041955;

}

html.aqua{
    --background: linear-gradient(180deg, #03FFFF 0%, #01ABB6 100%);

    --btn-color: linear-gradient(180deg, #FF68B3 0%, #FF007F 100%);


    --nav-acent:  linear-gradient(180deg, #FF68B3 0%, #FF007F 100%);

    --nav-text-active:#fff;
    --nav-text: #fff;
    --quote-text-container:  #2727279E;
    --quote-text:#FFFFFF;
    --acent: linear-gradient(180deg, #FF68B3 0%, #FF007F 100%);

    --list: #2727279E;
    --arrow-fill:#fff;
    --btn-text:#fff;
//   --quote-text-container-favorites: #C1FEFF9E;
//   --quote-text-favorites: #fff;
  --sound-btn:  linear-gradient(180deg, #FF68B3 0%, #FF007F 100%);

  --cog-fill: #FF68B3;
}

html.night-purple{

    --background: linear-gradient(180deg, #010C2C 0%, #5B026A 100%);

    --btn-color: linear-gradient(180deg, #686EFF 0%, #FF007F 100%);

    --nav-acent: linear-gradient(180deg, #686EFF 0%, #FF007F 100%);
    
    --nav-text-active:#fff;
    --nav-text: #fff;
    --quote-text-container:  #2727279E;
    --quote-text:#FFFFFF;
    --acent:  linear-gradient(180deg, #686EFF 0%, #FF007F 100%);;

    --list: #2727279E;
    --arrow-fill:#fff;
    --btn-text:#fff;
//   --quote-text-container-favorites: #C1FEFF9E;
//   --quote-text-favorites: #fff;
  --sound-btn: linear-gradient(180deg, #686EFF 0%, #FF007F 100%);


  --cog-fill: #686EFF;

}

html.gold-dark{

    --background: linear-gradient(180deg, #1E012C 0%, #FF6B00 100%);


    --btn-color:  linear-gradient(180deg, #5200FF 0%, #FF7A00 100%);


    --nav-acent: linear-gradient(180deg, #5200FF 0%, #FF7A00 100%);

    
    --nav-text-active:#fff;
    --nav-text: #fff;
    --quote-text-container:  #2727279E;
    --quote-text:#FFFFFF;
    --acent: linear-gradient(180deg, #5200FF 0%, #FF7A00 100%);


    --list: #2727279E;
    --arrow-fill:#fff;
    --btn-text:#fff;
//   --quote-text-container-favorites: #C1FEFF9E;
//   --quote-text-favorites: #fff;
  --sound-btn: linear-gradient(180deg, #5200FF 0%, #FF7A00 100%);
  ;


  --cog-fill: #686EFF;

}

