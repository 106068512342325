@import "../../scss/colors.scss";
.favourites_container{
display: flex;
flex-direction: column;
align-items: center;
background: var(--background);
height: 100vh;
justify-content: space-between;

.list{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
padding-top: 30px;


    
    .list_item{
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 100%;
        border-radius: 18px;
        padding: 10px 10px;
        margin-bottom: 25px;
        box-sizing: border-box;
        background-color: var(--quote-text-container);
        box-shadow: 0px 1px 3px rgba(0,0,0,0.12), 0px 1px 1px rgba(0,0,0,0.14), 0px 2px 1px rgba(0,0,0,0.2);

        .text{
            color: var(--quote-text);
            font-size: 32px;
            font-weight: 900;
            line-height: 45px;
            letter-spacing: 0.6270270347595215px;
            text-align: center;
           
             }
            
             .button_{
                position: absolute;
                padding: 5px;
                bottom: 0px;
                right: 10px;
                border: none;
                background-color: transparent;
                width: 40px;
                .like_svg{
                width: 100%;
                height: auto;
                fill: var(--svg_icon);
                }
             }
           
             
    }
}

.invisible{
    display: none;
}

.content{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding-top: 5%;
    padding-bottom: 2%;
}

.text_container {
    background: var( --quote-text-container-favorites);
    border: 1px solid #ffffff;
    border-radius: 12px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 15px;
      font-weight: 400;
      margin: 0;
      line-height: 19px;
      letter-spacing: 0.6270270347595215px;
      text-align: center;
      color: var(--quote-title);
    }
    .text {
      color: var( --quote-text-favorites);
      font-size: 32px;
      font-weight: 900;
      line-height: 45px;
      letter-spacing: 0.6270270347595215px;
      text-align: center;
    }
  }

  .button_container {
    display: flex;
    justify-content: space-between;
    width: 100%;


    .button_ {
        min-width: 54px;
        padding: 10px;
        height: 65px;
        border-radius: 15px;
        border: none;
        background: var(--acent);
        .arow_icon {
          width: 25px;
          fill: var(--arrow-fill);
        }
      }
  }

  .empty{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    justify-content: center;

    .empty_text{
      text-align: center;
      font-size: 18px;
      margin-top: 0;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0em;
      color:white;

     
    }
    .empty_link{
      text-decoration: none;
      border-radius: 15px;
      font-size: 18px;
    margin-top: 0;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    background: var(--acent);
    color: var( --nav-text-active);
    padding: 20px;

    }
  }

}
