@import "../../scss/colors.scss";
.quotes_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--background);
  justify-content: space-between;
  padding-top: 5%;
  padding-bottom: 10%;
  min-height: 100%;
  height: 100vh;


  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .top_container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .images {
        width: 100%;
        max-width: 204px;
        position: relative;
        bottom: -6px;
        display: block;
      }
      .button_ {
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
      }
      .custom_btn{
        .button_{
            display: flex;
            padding: 5px;
            flex-direction: column;
            align-items: end;
            .line{
                width: 100%;
                background-color:#00000099;
                height: 3px;
                margin-bottom: 15px;
            }.next_line{
                width: 55%;
                background-color: #00000099;
                height: 3px;
    
            }
        }
      }
    }

    .text_container {
      background: var(--quote-text-container);
      border: 1px solid #ffffff;
      border-radius: 12px;
      padding: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 15px;
        font-weight: 400;
        margin: 0;
        line-height: 19px;
        letter-spacing: 0.6270270347595215px;
        text-align: center;
        color: var(--quote-title);
      }
      .text {
        color: var(--quote-text);
        font-size: 32px;
        font-weight: 900;
        line-height: 45px;
        letter-spacing: 0.6270270347595215px;
        text-align: center;
      }
    }
  }

  .list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .user_err_message {
    color: var(--quote-text);
    font-size: 32px;
    font-weight: 900;
    line-height: 45px;
    letter-spacing: 0.6270270347595215px;
    text-align: center;
  }
  .button_container {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .add_to {
      .button_ {
        color: var(--btn-text);
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }

  .button_ {
    min-width: 54px;
    padding: 10px;
    height: 65px;
    border-radius: 15px;
    border: none;
    background: var(--acent);
    .arow_icon {
      width: 25px;
      fill: var(--arrow-fill);
    }
  }
}
