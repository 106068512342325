@import "../../scss/colors.scss";
.start_screen_container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  max-width: 480px;
  background: var(--background);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;


  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image{
      width: 100%;
      max-width: 354px;
    }

    .title {
      font-size: 33px;
      font-weight: 800;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--list_item);
    }
    .button_ {
      background: var(--btn-color);
      border-radius: 15px;
      padding: 10px 20px;
      min-width: 179px;
      height: 65px;
      border: none;
      font-family: Outfit;
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--btn-text);
    }
  }

  .elipse_side{
    width: 45%;
    height: 25%;
    top: 27%;
    left: 52%;
    transform: translate(-50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    max-width: 245px;
    max-height: 246px;
    background: #E4B0ED30;


position: absolute;
border-radius: 50%;
z-index: -1;
max-width: 245px;
max-height: 246px;



  }

  .elipse {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: aqua;
    border-radius: 50%;
  }
  .a {
    top: 20px;
    width: 14px;
    height: 14px;
    width: 14px;
    background: linear-gradient(0deg, rgba(149, 252, 227, 0.63), rgba(149, 252, 227, 0.63)),
linear-gradient(0deg, rgba(252, 180, 149, 0.63), rgba(252, 180, 149, 0.63));

    top: 9%;
    right: 14%;
  }
  .b {
    width: 9px;
    height: 9px;
    background: linear-gradient(0deg, #FFF9F3, #FFF9F3),
linear-gradient(0deg, rgba(252, 180, 149, 0.63), rgba(252, 180, 149, 0.63));

    top: 26%;
    right: 15%;
    
  }
  .c {
    width: 10.26px;
    height: 9.56px;
    top: 45%;
    right: 6%;
    background: linear-gradient(0deg, #FFB9B9, #FFB9B9),
linear-gradient(0deg, #FFF9F3, #FFF9F3);

  }
  .d{
    width: 14px;
height: 14px;
background: linear-gradient(0deg, rgba(252, 180, 149, 0.63), rgba(252, 180, 149, 0.63)),
linear-gradient(0deg, rgba(252, 180, 149, 0.63), rgba(252, 180, 149, 0.63));

top: 50%;
right: 33%;

  }
  .f{
    width: 22.57px;
height: 21.03px;
top: 16%;
left: 23%;
background: linear-gradient(0deg, #FFF9F3, #FFF9F3),
linear-gradient(0deg, #FFF9F3, #FFF9F3);

  }
  .g{
    left: 37%;
    top: 55%;
    width: 15px;
    height: 14px;
    background: linear-gradient(0deg, rgba(228, 176, 237, 0.19), rgba(228, 176, 237, 0.19)),
linear-gradient(0deg, #FFF9F3, #FFF9F3);

  }
}
