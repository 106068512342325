@import "../scss/colors.scss";
.nav_container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 30px 0px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background-color: transparent;
  align-items: center;
  flex-direction: column;
  margin: 0;

  .nav_list{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-top: 30px;
  }

  .link {
    padding: 5px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    border-radius: 8px;
    color: var(--nav-text);

  }

  .active {
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    background: var(--nav-acent);
    color: var(--nav-text-active);

    padding: 10px;
    border-radius: 8px;
  }


  
  .head_container {
    display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

    .invisible{
      width: 45px;
      height: 30px;
    background-color: transparent;

        }
  .button_{
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;

    .arrow{
      width: 100%;
      fill: var(--list_item);

    }
    .cog{
      width: 100%;
      fill:var(--cog-fill);
      
    }
  }

  .title_container{
    flex-grow: 1;
    text-align: center;
  }

    .title {
      font-size: 33px;
      font-weight: 800;
      line-height: 50px;
      letter-spacing: 0em;
      text-align: center;
      color: var(--list_item);
      text-align: center;
      flex-grow: 1;
      margin: 0;
    }


  }
}


