@import "../../scss/colors.scss";

.policy_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background);
    padding-bottom: 140px;
    padding-top: 30px;
    height: 100vh;

    .title {
        margin-bottom: 20px;
        color: white;
        font-size: 27px;
        font-weight: 700;
        line-height: normal;

    }

    .text {
        text-align: center;
        font-size: 20px;
        line-height: 35px;
        font-weight: 500;
        margin: 0;
        color: white;

    }
}